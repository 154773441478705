import * as React from 'react';

export const Print = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 512 512" {...props}>
    <path
      fill="currentColor"
      d="M475.934,108.229H380.35V54.285c0-5.995-4.862-10.857-10.857-10.857H142.507c-5.995,0-10.857,4.862-10.857,10.857v53.945
			H36.066C16.179,108.229,0,124.409,0,144.296v172.837C0,337.02,16.179,353.2,36.066,353.2h95.584v104.515
			c0,5.995,4.862,10.857,10.857,10.857h226.987c5.995,0,10.857-4.862,10.857-10.857V353.2h95.584
			c19.886,0,36.066-16.179,36.066-36.067V144.296C512,124.409,495.82,108.229,475.934,108.229z M380.349,129.942h25.897v30.385
			h-25.897V129.942z M153.363,65.142h205.273v95.186H153.363V65.142z M105.753,129.943h25.897v30.385h-25.897V129.943z
			 M358.637,446.858H153.363V303.879h205.273V446.858z M490.288,317.133h-0.001c0,7.915-6.439,14.354-14.352,14.354H380.35v-27.607
			h19.805c5.995,0,10.857-4.862,10.857-10.857s-4.862-10.857-10.857-10.857h-30.661H142.507h-30.661
			c-5.995,0-10.857,4.862-10.857,10.857s4.862,10.857,10.857,10.857h19.805v27.607H36.066c-7.913,0-14.352-6.439-14.352-14.354
			V144.296c0-7.914,6.439-14.354,14.352-14.354H84.04v41.241c0,5.995,4.861,10.857,10.857,10.857h322.207
			c5.995,0,10.857-4.862,10.857-10.857v-41.241h47.974c7.913,0,14.353,6.439,14.353,14.354V317.133z"
    />
    <circle fill="currentColor" cx="68.799" cy="219.261" r="13.602" />
    <path
      fill="currentColor"
      d="M252.849,386.456h-50.68c-5.995,0-10.857,4.86-10.857,10.857c0,5.995,4.861,10.857,10.857,10.857h50.68
      c5.995,0,10.857-4.862,10.857-10.857C263.706,391.316,258.844,386.456,252.849,386.456z
      M309.831,331.483H202.169c-5.995,0-10.857,4.862-10.857,10.857c0,5.996,4.861,10.857,10.857,10.857h107.661
			c5.995,0,10.857-4.861,10.857-10.857C320.687,336.345,315.826,331.483,309.831,331.483z"
    />
  </svg>
);
