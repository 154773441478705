import * as React from 'react';

export const DocumentAdd = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" {...props}>
    <path
      d="M10.76 20.36V21.44H15.4667C15.5495 21.0719 15.661 20.7108 15.8 20.36H10.76Z"
      fill="currentColor"
    />
    <path d="M15.12 13.8267H10.76V14.92H15.12V13.8267Z" fill="currentColor" />
    <path
      d="M16.2133 26.9733H7.62664C7.59182 26.9687 7.55836 26.9568 7.52838 26.9385C7.4984 26.9201 7.47256 26.8958 7.4525 26.867C7.43244 26.8381 7.41859 26.8054 7.41185 26.7709C7.4051 26.7365 7.4056 26.701 7.41331 26.6667V5.25335C7.42311 5.19448 7.45449 5.14138 7.50133 5.1044C7.54817 5.06743 7.60711 5.04922 7.66664 5.05335H17.5333V10.7733H23.1333V15.32C23.8412 15.3222 24.5454 15.4209 25.2266 15.6134V9.84002L18.48 2.93335H17.5466H7.54664C6.93952 2.97104 6.3704 3.24171 5.95801 3.68887C5.54563 4.13604 5.32183 4.72517 5.33331 5.33335V26.8533C5.3736 27.4501 5.63772 28.0096 6.07281 28.42C6.50791 28.8304 7.0819 29.0613 7.67997 29.0667H17.8933C17.2227 28.4654 16.6551 27.7583 16.2133 26.9733Z"
      fill="currentColor"
    />
    <path
      d="M10.76 18.1734H17.0667C17.3984 17.7771 17.7691 17.4153 18.1733 17.0934H10.76V18.1734Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.1466 18.5C23.791 18.5 24.3133 19.0223 24.3133 19.6667V26.7333C24.3133 27.3777 23.791 27.9 23.1466 27.9C22.5023 27.9 21.98 27.3777 21.98 26.7333V19.6667C21.98 19.0223 22.5023 18.5 23.1466 18.5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4333 23.2C18.4333 22.5557 18.9557 22.0334 19.6 22.0334H26.68C27.3243 22.0334 27.8467 22.5557 27.8467 23.2C27.8467 23.8444 27.3243 24.3667 26.68 24.3667H19.6C18.9557 24.3667 18.4333 23.8444 18.4333 23.2Z"
      fill="currentColor"
    />
  </svg>
);
