import * as React from 'react';

export const InboxMails = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 14" {...props}>
    <path
      fill="currentColor"
      d="M0 6.60004C0 6.04776 0.447715 5.60004 1 5.60004H5.2C5.53435 5.60004 5.84658 5.76714 6.03205 6.04534L7.13518 7.70004H8.86482L9.96795 6.04534C10.1534 5.76714 10.4656 5.60004 10.8 5.60004H15C15.5523 5.60004 16 6.04776 16 6.60004C16 7.15233 15.5523 7.60004 15 7.60004H11.3352L10.232 9.25474C10.0466 9.53294 9.73435 9.70004 9.4 9.70004H6.6C6.26565 9.70004 5.95342 9.53294 5.76795 9.25474L4.66482 7.60004H1C0.447715 7.60004 0 7.15233 0 6.60004Z M4.668 0L11.332 1.19209e-07C11.7785 0.000237167 12.2166 0.125026 12.5961 0.360336C12.975 0.595314 13.281 0.931198 13.4797 1.33031L13.4805 1.332L15.8942 6.15227C15.9638 6.29126 16 6.4446 16 6.60004V10.8C16 11.4365 15.7471 12.047 15.2971 12.4971C14.847 12.9471 14.2365 13.2 13.6 13.2H2.4C1.76348 13.2 1.15303 12.9471 0.702944 12.4971C0.252856 12.047 0 11.4365 0 10.8V6.60004C0 6.4446 0.0362364 6.29126 0.105833 6.15227L2.51947 1.332L2.52024 1.33045C2.71895 0.931275 3.02495 0.595341 3.40392 0.360336C3.78339 0.125026 4.2215 0.000236928 4.668 0ZM3.41501 1.77701L4.30917 2.22473L2 6.83637V10.8C2 10.9061 2.04214 11.0078 2.11716 11.0828C2.19217 11.1579 2.29391 11.2 2.4 11.2H13.6C13.7061 11.2 13.8078 11.1579 13.8828 11.0828C13.9579 11.0078 14 10.9061 14 10.8V6.83637L11.6908 2.22473L11.6895 2.222C11.6564 2.15536 11.6053 2.09927 11.5421 2.06006C11.4789 2.02087 11.406 2.00007 11.3316 2H4.66837C4.59401 2.00007 4.52114 2.02087 4.45794 2.06006C4.3947 2.09927 4.34365 2.15536 4.31053 2.222L3.41501 1.77701Z"
    />
  </svg>
);

export const OutgoingMails = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.9349 1.35536C16.0703 0.998679 15.9943 0.580146 15.7071 0.292893C15.4211 0.00691429 15.005 -0.0696437 14.6493 0.0632191L0.669643 4.95611C0.282643 5.09156 0.0175386 5.44953 0.000824841 5.8592C-0.015889 6.26888 0.219175 6.64726 0.593855 6.81378L6.54231 9.45754L9.18607 15.406C9.3526 15.7807 9.73097 16.0157 10.1406 15.999C10.5503 15.9823 10.9083 15.7172 11.0437 15.3302L15.9349 1.35536ZM11.1943 3.39145L3.71107 6.01057L7.07851 7.50722L11.1943 3.39145ZM8.49269 8.92146L12.6083 4.80585L9.98928 12.2888L8.49269 8.92146Z"
    />
  </svg>
);
