// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2hsF6panew2rSQfAF_Rxrh {\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  box-sizing: border-box;\n  padding: 8px 12px;\n  font-family: inherit;\n  background-color: transparent;\n  border: none;\n  font-size: 16px;\n}\n._2hsF6panew2rSQfAF_Rxrh:hover {\n  background-color: #ededed;\n}\n.q-Rc-9Ws4G6CSyWXIYhsw {\n  width: 100%;\n}\n._3Ju2YlVilB45IZ0W_mDv7Y {\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/components/Button/Button.styl"],"names":[],"mappings":"AAAA;EACE,oBAAS;EACT,uBAAiB;EACjB,mBAAa;EACb,sBAAY;EACZ,iBAAS;EACT,oBAAa;EACb,6BAAkB;EAClB,YAAQ;EACR,eAAW;AACb;AACE;EACE,yBAAkB;AACtB;AAGA;EACE,WAAO;AADT;AAIA;EACE,YAAQ;AAFV","sourcesContent":[".root {\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  box-sizing: border-box;\n  padding: 8px 12px;\n  font-family: inherit;\n  background-color: transparent;\n  border: none;\n  font-size: 16px;\n\n  &:hover {\n    background-color: #ededed;\n  }\n}\n\n.fillHorizontal {\n  width: 100%;\n}\n\n.fillVertical {\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_2hsF6panew2rSQfAF_Rxrh",
	"fillHorizontal": "q-Rc-9Ws4G6CSyWXIYhsw",
	"fillVertical": "_3Ju2YlVilB45IZ0W_mDv7Y"
};
export default ___CSS_LOADER_EXPORT___;
