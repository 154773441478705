// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._37ivzPB50xzUIvFhdqUebB {\n  background: #fff;\n  box-shadow: 0 4px 4px rgba(0,0,0,0.04);\n  border-radius: 4px;\n  border: 1px solid #dedede;\n}\n", "",{"version":3,"sources":["webpack://src/components/Paper.styl"],"names":[],"mappings":"AAAA;EACI,gBAAY;EACZ,sCAAY;EACZ,kBAAe;EACf,yBAAO;AACX","sourcesContent":[".root {\n    background: #FFFFFF;\n    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);\n    border-radius: 4px;\n    border 1px solid #dedede\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_37ivzPB50xzUIvFhdqUebB"
};
export default ___CSS_LOADER_EXPORT___;
