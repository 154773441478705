// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "th._3oJ_vzMWxgI2QBcCl78gbx,\ntd._3oJ_vzMWxgI2QBcCl78gbx {\n  padding: 16px;\n}\ntd.HW9f6E4MUCcEryTWgfWBI {\n  font-family: Courier;\n}\n", "",{"version":3,"sources":["webpack://src/components/Table/Dashboard/DashboardTableStyles.styl"],"names":[],"mappings":"AAAA;;EACE,aAAS;AAEX;AACA;EACE,oBAAa;AACf","sourcesContent":["th.column, td.column {\n  padding: 16px;\n}\n\ntd.columnAddress {\n  font-family: Courier;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column": "_3oJ_vzMWxgI2QBcCl78gbx",
	"columnAddress": "HW9f6E4MUCcEryTWgfWBI"
};
export default ___CSS_LOADER_EXPORT___;
