// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2AojO7srwM8dEwTIrTh4Qo {\n  cursor: pointer;\n  opacity: 1;\n}\n._2AojO7srwM8dEwTIrTh4Qo:hover {\n  opacity: 0.6;\n}\n", "",{"version":3,"sources":["webpack://src/ui/Icons/styles.styl"],"names":[],"mappings":"AAAA;EACE,eAAO;EACP,UAAS;AACX;AAAE;EACE,YAAS;AAEb","sourcesContent":[".hoverIcon {\n  cursor pointer\n  opacity: 1\n  &:hover {\n    opacity: 0.6\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hoverIcon": "_2AojO7srwM8dEwTIrTh4Qo"
};
export default ___CSS_LOADER_EXPORT___;
