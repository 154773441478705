import * as React from 'react';

export const ControlEdit = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      d="M22 15.358a.5.5 0 1 1 1 0v8.81a2.853 2.853 0 0 1-2.853 2.853H7.853A2.853 2.853 0 0 1 5 24.168V11.874A2.853 2.853 0 0 1 7.853 9.02h9.376a.5.5 0 1 1 0 1H7.853C6.83 10.02 6 10.85 6 11.874v12.294c0 1.023.83 1.853 1.853 1.853h12.294c1.023 0 1.853-.83 1.853-1.853v-8.81zM25.61 4l2.829 2.828-11.916 11.916-3.843 1.195 1.015-4.023L25.61 4zm0 1.414L14.598 16.428l-.511 2.027 1.904-.592L27.025 6.828 25.61 5.414z"
    />
  </svg>
);
