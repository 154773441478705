// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1Yg8fnPb6WeAxleRy8VH__._1Yg8fnPb6WeAxleRy8VH__ {\n  min-width: 346px;\n  max-width: 346px;\n  height: 346px;\n  width: 346px;\n  margin: 4px;\n}\n._1Q5-OTap7dIifk3vPsKP39 {\n  border: 4px solid #c2c2c2;\n  border-radius: 50%;\n  height: 256px;\n  width: 256px;\n}\n._1Q5-OTap7dIifk3vPsKP39.oLk7VD4QJyq2zh4TY2K8V {\n  border-color: #4ae3a7;\n}\n._1Q5-OTap7dIifk3vPsKP39._1bJiJPrBuhzBbMWjx-AXov {\n  border-color: #f00;\n}\n._1SWcZRYC5DbdeUPSSnhYzX {\n  color: #4ae3a7;\n}\n._1WR9ni06rgCEOQkrzvxUcT {\n  color: #f00;\n}\n", "",{"version":3,"sources":["webpack://src/components/Dashboard/DashboardCard.styl"],"names":[],"mappings":"AAEA;EACE,gBAAW;EACX,gBAAW;EACX,aAAQ;EACR,YAAO;EACP,WAAQ;AADV;AAIA;EACE,yBAAQ;EACR,kBAAe;EACf,aAAO;EACP,YAAM;AAFR;AAIE;EACE,qBAAc;AAFlB;AAKE;EACE,kBAAc;AAHlB;AAOA;EACE,cAAO;AALT;AAQA;EACE,WAAO;AANT","sourcesContent":["\n\n.DashboardCard.DashboardCard {\n  min-width: 346px;\n  max-width: 346px;\n  height: 346px;\n  width: 346px;\n  margin: 4px;\n}\n\n.circled {\n  border: 4px solid #c2c2c2;\n  border-radius: 50%;\n  height 256px;\n  width 256px;\n\n  &.successBorder {\n    border-color: #4ae3a7;\n  }\n\n  &.errorBorder {\n    border-color: #FF0000;\n  }\n}\n\n.success {\n  color: #4ae3a7;\n}\n\n.error {\n  color: #FF0000\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DashboardCard": "_1Yg8fnPb6WeAxleRy8VH__",
	"circled": "_1Q5-OTap7dIifk3vPsKP39",
	"successBorder": "oLk7VD4QJyq2zh4TY2K8V",
	"errorBorder": "_1bJiJPrBuhzBbMWjx-AXov",
	"success": "_1SWcZRYC5DbdeUPSSnhYzX",
	"error": "_1WR9ni06rgCEOQkrzvxUcT"
};
export default ___CSS_LOADER_EXPORT___;
