import * as React from 'react';

export const Info = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 512 512" {...props}>
    <path
      fill="currentColor"
      d="M256 0C120.477 0 10.224 110.255 10.224 245.778c0 62.171 23.12 121.186 65.206 166.725l-13.707 88.342a9.675 9.675 0 009.557 11.156c1.52 0 3.046-.357 4.447-1.083l79.241-41.036c31.825 14.386 65.788 21.675 101.032 21.675 135.521 0 245.778-110.255 245.778-245.778C501.778 110.255 391.523 0 256 0zm0 472.211c-33.973 0-66.646-7.347-97.114-21.835a9.67 9.67 0 00-8.601.146l-66.524 34.45 11.51-74.177a9.675 9.675 0 00-2.582-8.184c-40.704-42.373-63.121-98.072-63.121-156.835C29.568 120.922 131.145 19.344 256 19.344s226.433 101.577 226.433 226.433S380.856 472.211 256 472.211z M256 70.708c-28.236 0-51.208 22.972-51.208 51.208 0 28.236 22.972 51.208 51.208 51.208 28.238 0 51.208-22.972 51.208-51.208 0-28.238-22.972-51.208-51.208-51.208zm0 83.072c-17.57 0-31.864-14.294-31.864-31.864S238.43 90.052 256 90.052c17.571 0 31.864 14.294 31.864 31.864S273.57 153.78 256 153.78zM312.758 357.74h-11.013V199.148c0-5.342-4.331-9.672-9.672-9.672h-92.828c-5.342 0-9.672 4.331-9.672 9.672v32.143c0 5.342 4.331 9.672 9.672 9.672h11.013V357.74h-11.013c-5.342 0-9.672 4.331-9.672 9.672v32.143c0 5.343 4.331 9.673 9.672 9.673h113.514c5.342 0 9.672-4.331 9.672-9.672v-32.143c-.001-5.342-4.332-9.673-9.673-9.673zm-9.673 32.144h-94.17v-12.8h11.013c5.342 0 9.672-4.331 9.672-9.672V231.291c0-5.342-4.331-9.672-9.672-9.672h-11.013v-12.798H282.4v158.593c0 5.342 4.331 9.672 9.672 9.672h11.013v12.798z"
    />
  </svg>
);
