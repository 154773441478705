import * as React from 'react';

export const PrintFormRemove = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M3.25 6.3h13.5a.8.8 0 1 0 0-1.6H3.25a.8.8 0 1 0 0 1.6z" />
      <path d="M14.45 5.5a.8.8 0 1 1 1.6 0V16a2.3 2.3 0 0 1-2.3 2.3h-7.5a2.3 2.3 0 0 1-2.3-2.3V5.5a.8.8 0 1 1 1.6 0V16a.7.7 0 0 0 .7.7h7.5a.7.7 0 0 0 .7-.7V5.5zm-6.65 0a.8.8 0 1 1-1.6 0V4a2.3 2.3 0 0 1 2.3-2.3h3A2.3 2.3 0 0 1 13.8 4v1.5a.8.8 0 1 1-1.6 0V4a.7.7 0 0 0-.7-.7h-3a.7.7 0 0 0-.7.7v1.5z" />
      <path d="M7.7 9.25v4.5a.8.8 0 1 0 1.6 0v-4.5a.8.8 0 1 0-1.6 0zM10.7 9.25v4.5a.8.8 0 1 0 1.6 0v-4.5a.8.8 0 1 0-1.6 0z" />
    </g>
  </svg>
);

export const PrintFormEdit = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M8.929 3.843h-5C2.698 3.843 1.7 4.84 1.7 6.07v10c0 1.231.998 2.229 2.229 2.229h10c1.23 0 2.228-.998 2.228-2.229v-5a.8.8 0 1 0-1.6 0v5a.629.629 0 0 1-.628.629h-10a.629.629 0 0 1-.629-.629v-10c0-.347.281-.628.629-.628h5a.8.8 0 1 0 0-1.6z" />
      <path d="M16.994 3.006c.904.904.904 2.37 0 3.274l-6.785 6.786a.8.8 0 0 1-.372.21l-2.857.714a.8.8 0 0 1-.97-.97l.714-2.857a.8.8 0 0 1 .21-.372l6.786-6.785a2.315 2.315 0 0 1 3.274 0zm-1.131 2.143a.715.715 0 0 0-1.012-1.012l-6.629 6.63-.337 1.348 1.349-.337 6.629-6.63z" />
    </g>
  </svg>
);

export const PrintFormDownload = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M16.7 12.5v3.333a.867.867 0 0 1-.867.867H4.167a.867.867 0 0 1-.867-.867V12.5a.8.8 0 1 0-1.6 0v3.333A2.467 2.467 0 0 0 4.167 18.3h11.666a2.467 2.467 0 0 0 2.467-2.467V12.5a.8.8 0 1 0-1.6 0z" />
      <path d="M6.399 7.768a.8.8 0 1 0-1.131 1.131l4.166 4.167a.8.8 0 0 0 1.132 0l4.166-4.167a.8.8 0 0 0-1.131-1.131L10 11.368l-3.601-3.6z" />
      <path d="M10.8 12.5v-10a.8.8 0 1 0-1.6 0v10a.8.8 0 1 0 1.6 0z" />
    </g>
  </svg>
);

export const PrintFormDoc = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="nonzero">
      <path
        fill="currentColor"
        d="M2.25 1.08C2.25.483 2.733 0 3.33 0h7.92l4.5 3.78v13.14A1.08 1.08 0 0 1 14.67 18H3.33a1.08 1.08 0 0 1-1.08-1.08V1.08zm2.442 7.908h.658c.324 0 .54.093.65.28.108.185.163.452.163.8 0 .251-.037.468-.11.65-.132.332-.367.498-.703.498h-.658V8.988zm.778-.592H4v3.413h1.47c.516 0 .897-.213 1.144-.637.182-.315.273-.7.273-1.153 0-.18-.021-.368-.065-.565a1.519 1.519 0 0 0-.243-.551A1.127 1.127 0 0 0 6 8.47a1.794 1.794 0 0 0-.529-.074zm3.464 2.903a.824.824 0 0 1-.676-.312c-.168-.209-.252-.505-.252-.89 0-.384.084-.68.252-.889a.824.824 0 0 1 .676-.312c.282 0 .507.104.673.314.166.209.249.505.249.887 0 .385-.083.681-.25.89a.815.815 0 0 1-.672.312zm1.635-1.202c0-.625-.172-1.094-.517-1.407-.257-.266-.63-.398-1.118-.398-.488 0-.86.132-1.118.398-.346.313-.519.782-.519 1.407 0 .613.173 1.083.519 1.408.257.266.63.398 1.118.398.488 0 .86-.132 1.118-.398.345-.325.517-.795.517-1.408zm.396.033c0 .55.14.985.421 1.301.28.315.667.472 1.158.472.396 0 .722-.116.976-.35.253-.232.411-.535.473-.907h-.706a1.066 1.066 0 0 1-.18.396c-.132.164-.323.246-.573.246a.75.75 0 0 1-.603-.286c-.158-.191-.237-.478-.237-.86 0-.383.075-.68.224-.888a.715.715 0 0 1 .614-.314c.255 0 .447.074.577.222a.963.963 0 0 1 .178.37H14a1.223 1.223 0 0 0-.236-.636c-.266-.377-.686-.565-1.26-.565-.429 0-.782.14-1.06.421-.32.323-.48.782-.48 1.378z"
      />
      <path fill="#323232" d="M11.25 0v2.7c0 .597.483 1.08 1.08 1.08h3.42" opacity=".396" />
    </g>
  </svg>
);

export const PrintFormPdf = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" {...props}>
    <g fill="#000" fillRule="nonzero">
      <path
        fill="currentColor"
        d="M2.25 1.08C2.25.483 2.733 0 3.33 0h7.92l4.5 3.78v13.14A1.08 1.08 0 0 1 14.67 18H3.33a1.08 1.08 0 0 1-1.08-1.08V1.08zm3.583 14.391c1.14 0 1.713-1.713 1.995-3.234.72-.399 1.52-.763 2.288-1.042.813.457 1.887.955 2.711.955.71 0 1.17-.392 1.23-1.048.04-.435-.122-.712-.264-.868-.258-.284-.682-.428-1.258-.428-.753 0-1.652.24-2.33.465a16.363 16.363 0 0 1-2.116-1.513c-.075-2.204-.629-3.414-1.566-3.414-.16 0-.326.038-.492.115-.457.21-.596.553-.632.802-.127.897.905 2.043 1.854 2.89a15.93 15.93 0 0 1-.189 2.552c-1.14.673-2.42 1.637-2.451 2.584-.009.27.077.655.54.962.222.147.45.222.68.222zm.997-2.62c-.554.38-1.775 1.32-1.21 1.695.576.382 1.038-1.036 1.21-1.695zm4.435-2.012c.557.26 1.895.798 1.951.185.056-.608-1.364-.332-1.951-.185zm-3.278.333c.05-.428.083-.866.099-1.297a.051.051 0 0 1 .052.006c.337.26.69.51 1.044.744a.047.047 0 0 1-.029.04c-.376.156-.753.328-1.118.51a.047.047 0 0 1-.048-.003zM6.385 6.225c-.501.23.415 1.287.796 1.686-.065-.547-.297-1.916-.796-1.686z"
      />
      <path fill="#323232" d="M11.25 0v2.7c0 .597.483 1.08 1.08 1.08h3.42" opacity=".396" />
    </g>
  </svg>
);

export const PrintFormZoom = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M4.3 5.715L7.215 2.8h6.918c.092 0 .167.075.167.167v2.416a.8.8 0 1 0 1.6 0V2.967c0-.976-.791-1.767-1.767-1.767h-7.25a.8.8 0 0 0-.565.234L2.934 4.818a.8.8 0 0 0-.234.565V16.5c0 .976.791 1.767 1.767 1.767h9.666a.8.8 0 1 0 0-1.6H4.467A.167.167 0 0 1 4.3 16.5V5.715z" />
      <path d="M12.033 14.533a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-1.6a2.4 2.4 0 1 1 0-4.8 2.4 2.4 0 0 1 0 4.8z" />
      <path d="M17.932 15.301l-2.8-2.8a.8.8 0 0 0-1.131 1.131l2.8 2.8a.8.8 0 0 0 1.131-1.131zM7.147 5.467v-3.4a.8.8 0 1 1 1.6 0v4.2a.8.8 0 0 1-.8.8h-4.2a.8.8 0 1 1 0-1.6h3.4z" />
    </g>
  </svg>
);

export const PrintFormCopy = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M9.75 6.95h5.75a2.8 2.8 0 0 1 2.8 2.8v5.75a2.8 2.8 0 0 1-2.8 2.8H9.75a2.8 2.8 0 0 1-2.8-2.8V9.75a2.8 2.8 0 0 1 2.8-2.8zm0 1.6a1.2 1.2 0 0 0-1.2 1.2v5.75a1.2 1.2 0 0 0 1.2 1.2h5.75a1.2 1.2 0 0 0 1.2-1.2V9.75a1.2 1.2 0 0 0-1.2-1.2H9.75z" />
      <path d="M4.75 11.45H4a.7.7 0 0 1-.7-.7V4a.7.7 0 0 1 .7-.7h6.75a.7.7 0 0 1 .7.7v.75a.8.8 0 1 0 1.6 0V4a2.3 2.3 0 0 0-2.3-2.3H4A2.3 2.3 0 0 0 1.7 4v6.75a2.3 2.3 0 0 0 2.3 2.3h.75a.8.8 0 1 0 0-1.6z" />
    </g>
  </svg>
);
