import * as React from 'react';

export const Time = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="-1 -1 20 20" {...props}>
    <path
      fill="currentColor"
      d="M5.00806827,3 L8.97177134,8.24966934 L12.9787146,3 L5.00806827,3 Z M5.08875946,15 L12.926119,15 L9.02985333,10.1042506 L5.08875946,15 Z M3,2 L15,2 L9.54545455,9.14623547 L15,16 L3,16 L8.45454545,9.22419402 L3,2 Z"
      fillRule="nonzero"
    />
  </svg>
);
