import * as React from 'react';

export const ControlSteps = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      d="M24.662 8a.5.5 0 0 1 0 1h-12.9a.5.5 0 1 1 0-1h12.9zm0 8a.5.5 0 0 1 0 1h-12.9a.5.5 0 1 1 0-1h12.9zm0 8a.5.5 0 0 1 0 1h-12.9a.5.5 0 1 1 0-1h12.9zM6.887 7.323L7.966 8.64l2.109-3.404a.5.5 0 1 1 .85.526L8.078 10.36 6.113 7.956a.5.5 0 0 1 .774-.633zm0 8l1.079 1.318 2.109-3.404a.5.5 0 1 1 .85.526L8.078 18.36l-1.965-2.403a.5.5 0 0 1 .774-.633zm0 8l1.079 1.318 2.109-3.404a.5.5 0 1 1 .85.526L8.078 26.36l-1.965-2.403a.5.5 0 0 1 .774-.633z"
    />
  </svg>
);
