// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2xa_mutUAeige0bIGRDDlU {\n  height: 180px;\n  width: 180px;\n  border-radius: 50%;\n  align-items: center;\n  justify-content: center;\n}\n._2DgmgzSYpPpbO-N76Kz3yv {\n  border-radius: 50%;\n  height: 150px;\n  width: 150px;\n  align-items: center;\n  justify-content: center;\n}\n._3-TKBrwKs7tm7_pc0h85IW {\n  background-color: #808080;\n}\n._2TYHfUeACLfO6Li9CEAFfs {\n  background-color: #46d7b6;\n}\n", "",{"version":3,"sources":["webpack://src/pages/Issue/components/IssueDetailsModal/IssueDetailsModals.styl"],"names":[],"mappings":"AAAA;EACE,aAAQ;EACR,YAAO;EACP,kBAAe;EACf,mBAAa;EACb,uBAAiB;AACnB;AAEA;EACE,kBAAe;EACf,aAAQ;EACR,YAAO;EACP,mBAAa;EACb,uBAAiB;AAAnB;AAGA;EACE,yBAAkB;AADpB;AAIA;EACE,yBAAkB;AAFpB","sourcesContent":[".circleBorder {\n  height: 180px;\n  width: 180px;\n  border-radius: 50%;\n  align-items: center;\n  justify-content: center;\n}\n\n.circle {\n  border-radius: 50%;\n  height: 150px;\n  width: 150px;\n  align-items: center;\n  justify-content: center;\n}\n\n.circleCanceled {\n  background-color: gray;\n}\n\n.circleConfirmed {\n  background-color: #46d7b6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circleBorder": "_2xa_mutUAeige0bIGRDDlU",
	"circle": "_2DgmgzSYpPpbO-N76Kz3yv",
	"circleCanceled": "_3-TKBrwKs7tm7_pc0h85IW",
	"circleConfirmed": "_2TYHfUeACLfO6Li9CEAFfs"
};
export default ___CSS_LOADER_EXPORT___;
