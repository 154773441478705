// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3fPxI9QCE7NB4IYJqhDaa3 {\n  animation: _38LPXr8vEbPlMFHVfbt4FW 0.6s linear infinite;\n}\n@-moz-keyframes _38LPXr8vEbPlMFHVfbt4FW {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes _38LPXr8vEbPlMFHVfbt4FW {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n@-o-keyframes _38LPXr8vEbPlMFHVfbt4FW {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n@keyframes _38LPXr8vEbPlMFHVfbt4FW {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/Table/Spinner/Spinner.styl"],"names":[],"mappings":"AASA;EACC,uDAAW;AARZ;AAFA;EACC;IACC,oBAAW;EAIX;EAFD;IACC,yBAAW;EAIX;AACF;AAVA;EACC;IACC,oBAAW;EAYX;EAVD;IACC,yBAAW;EAYX;AACF;AAlBA;EACC;IACC,oBAAW;EAoBX;EAlBD;IACC,yBAAW;EAoBX;AACF;AA1BA;EACC;IACC,oBAAW;EA4BX;EA1BD;IACC,yBAAW;EA4BX;AACF","sourcesContent":["@keyframes spin {\n\tfrom {\n\t\ttransform: rotate(0);\n\t}\n\tto {\n\t\ttransform: rotate(360deg);\n\t}\n}\n\n.spinner {\n\tanimation: spin 0.6s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "_3fPxI9QCE7NB4IYJqhDaa3",
	"spin": "_38LPXr8vEbPlMFHVfbt4FW"
};
export default ___CSS_LOADER_EXPORT___;
