import * as React from 'react';

export const RadioButton = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 510 510" {...props}>
    <path
      fill="currentColor"
      d="M255 127.5c-71.4 0-127.5 56.1-127.5 127.5S183.6 382.5 255 382.5 382.5 326.4 382.5 255 326.4 127.5 255 127.5zM255 0C114.75 0 0 114.75 0 255s114.75 255 255 255 255-114.75 255-255S395.25 0 255 0zm0 459c-112.2 0-204-91.8-204-204S142.8 51 255 51s204 91.8 204 204-91.8 204-204 204z"
    />
  </svg>
);
