// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3NEi0zh08k4XLKMor2qHWU._3NEi0zh08k4XLKMor2qHWU {\n  padding: 8px 12px;\n  border-radius: 4px;\n}\n", "",{"version":3,"sources":["webpack://src/components/Layouts/SideBarButton.styl"],"names":[],"mappings":"AAAA;EACE,iBAAS;EACT,kBAAe;AACjB","sourcesContent":[".root.root {\n  padding: 8px 12px;\n  border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_3NEi0zh08k4XLKMor2qHWU"
};
export default ___CSS_LOADER_EXPORT___;
