import * as React from 'react';

export const NoData = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 80 80" {...props}>
    <path
      fill="currentColor"
      d="M52.64 52.864A12.703 12.703 0 0 1 40 64.09a12.703 12.703 0 0 1-12.64-11.227H3.5v20.954c0 1.072.883 1.955 1.955 1.955h69.09a1.965 1.965 0 0 0 1.955-1.955V52.864H52.64zm26.86-1.5v22.454a4.965 4.965 0 0 1-4.955 4.955H5.455A4.965 4.965 0 0 1 .5 73.818V51.364a1.5 1.5 0 0 1 1.5-1.5h26.773a1.5 1.5 0 0 1 1.5 1.5A9.705 9.705 0 0 0 40 61.09a9.705 9.705 0 0 0 9.727-9.727 1.5 1.5 0 0 1 1.5-1.5H78a1.5 1.5 0 0 1 1.5 1.5zm-8.129-18.606a1.5 1.5 0 1 1 2.894-.789l5.182 19a1.5 1.5 0 1 1-2.894.79l-5.182-19zm-67.924 19a1.5 1.5 0 0 1-2.894-.789l5.182-19a1.5 1.5 0 1 1 2.894.79l-5.182 19zm10.417-32.591v27.015a1.5 1.5 0 0 1-3 0V18.545c0-.397.158-.779.439-1.06L26.848 1.939A1.5 1.5 0 0 1 27.91 1.5h32.818c4.629 0 8.41 3.78 8.41 8.41v36.272a1.5 1.5 0 1 1-3 0V9.909c0-2.971-2.438-5.409-5.41-5.409H28.53L13.864 19.167zM28.136 8.182a1.5 1.5 0 0 1 3 0v5.182c0 4.608-3.931 8.409-8.581 8.409h-5.01a1.5 1.5 0 0 1 0-3h5.01c3.015 0 5.581-2.481 5.581-5.41V8.183z"
    />
  </svg>
);
