import * as React from 'react';

export const ONE = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    height="500"
    x="0"
    y="0"
    overflow="visible"
    viewBox="0.25 0.5 500 500"
    {...props}
  >
    <path
      fill="#1C2B5B"
      d="M250 0C111.93 0 0 111.93 0 250s111.93 250 250 250 250-111.93 250-250S388.07 0 250 0zm0 471c-122.05 0-221-98.95-221-221S127.94 29 250 29c122.05 0 221 98.94 221 221 0 122.05-98.95 221-221 221z"
    ></path>
    <path
      fill="#1C2B5B"
      d="M336.76 97.98c-36.26-.03-65.51 29.31-65.51 65.56v72.7c-7 .46-13.82.75-21 .75s-14 .29-21 .69v-74.14c-1-36.33-30.73-65.3-67.06-64.72-35.51.57-63.94 29.22-64.94 64.72v173.75c1 36.33 30.73 65.3 67.05 64.72 35.51-.57 63.95-29.21 64.95-64.72v-72.7c7-.46 13.82-.75 21-.75s14-.29 21-.69v74.14c0 36.33 30.23 65.3 66.56 64.72 35.51-.57 64.44-29.21 64.44-64.72V163.54c0-36.25-29.24-65.58-65.49-65.56zM202.25 337.29c0 21.44-17.58 38.81-39.02 38.8-21.43-.01-38.98-17.37-38.98-38.8v-17.43c0-15.39 12.37-29.98 32.82-39.06 14.4-6.21 29.18-10.64 45.18-13.16v69.65zm0-96.76c-19 2.79-38.25 8.1-56.11 15.8-7.74 3.39-14.89 7.74-21.89 12.94V163.54c0-21.37 17.6-38.67 38.97-38.7 21.4-.01 39.03 17.31 39.03 38.7v76.99zm173 96.76c0 21.37-17.09 38.67-38.46 38.7-21.4.01-38.54-17.3-38.54-38.7v-76.98c19-2.79 37.75-8.1 55.61-15.8 7.74-3.39 15.39-7.74 21.39-12.94v105.72zm0-156.32c0 15.33-11.87 29.91-32.32 38.96-14.41 6.22-29.68 10.63-44.68 13.16v-69.55c0-21.44 17.08-38.81 38.52-38.8 21.43.01 38.48 17.38 38.48 38.8v17.43z"
    ></path>
  </svg>
);
