import * as React from 'react';

export const NotificationCounterWrap = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fill="#FFCB02"
      stroke="currentColor"
      strokeWidth="1.5"
      d="M6.775 22.41l5.974-4.922H21c.69 0 1.25-.56 1.25-1.25V5c0-.69-.56-1.25-1.25-1.25H3c-.69 0-1.25.56-1.25 1.25v11.238c0 .69.56 1.25 1.25 1.25h3.775v4.922z"
    />
  </svg>
);
