// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3o9FcSmDYGSyKlSSAwOBZC {\n  height: 1px;\n  width: 60px;\n  background-color: #dedede;\n}\n._2PGITHAaY6is-HqHPWf_v- {\n  height: 2px;\n  background: #00ade8;\n  background: linear-gradient(90deg, #00ade8 0%, #00e8a2 100%);\n}\n.v6JpTyzb_nc7bhEuXqkjG {\n  width: 100%;\n}\n._1QGV72OKQKvOIFOdBWDbLk {\n  border-left: 1px solid #c2c2c2;\n}\n", "",{"version":3,"sources":["webpack://src/components/Base/components/Divider/styles.styl"],"names":[],"mappings":"AAAA;EACE,WAAO;EACP,WAAM;EACN,yBAAiB;AACnB;AAEA;EACE,WAAO;EACP,mBAAW;EACX,4DAAW;AAAb;AAGA;EACE,WAAM;AADR;AAIA;EACE,8BAAa;AAFf","sourcesContent":[".common {\n  height 1px\n  width 60px\n  background-color #dedede\n}\n\n.colorful {\n  height 2px\n  background rgb(0,173,232)\n  background linear-gradient(90deg, rgba(0,173,232,1) 0%, rgba(0,232,162,1) 100%)\n}\n\n.fullwidth {\n  width 100%\n}\n\n.verticalCommon {\n  border-left: 1px solid #c2c2c2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"common": "_3o9FcSmDYGSyKlSSAwOBZC",
	"colorful": "_2PGITHAaY6is-HqHPWf_v-",
	"fullwidth": "v6JpTyzb_nc7bhEuXqkjG",
	"verticalCommon": "_1QGV72OKQKvOIFOdBWDbLk"
};
export default ___CSS_LOADER_EXPORT___;
