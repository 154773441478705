import * as React from 'react';

export const Calendar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="-1 -1 24 24" {...props}>
    <path
      fill="currentColor"
      d="M15.5,4.07285714 L6.5,4.07285714 L6.5,6.33023568 L5.5,6.33023568 L5.5,4.07285714 L2.5,4.07285714 L2.5,20.0728571 L19.5,20.0728571 L19.5,4.07285714 L16.5,4.07285714 L16.5,6.33023568 L15.5,6.33023568 L15.5,4.07285714 Z M16.5,3.07285714 L20.5,3.07285714 L20.5,21.0728571 L1.5,21.0728571 L1.5,3.07285714 L5.5,3.07285714 L5.5,0.93 L6.5,0.93 L6.5,3.07285714 L15.5,3.07285714 L15.5,0.93 L16.5,0.93 L16.5,3.07285714 Z M4.5,8.07285714 L5.5,8.07285714 L5.5,10.0728571 L4.5,10.0728571 L4.5,8.07285714 Z M4.5,12.0728571 L5.5,12.0728571 L5.5,14.0728571 L4.5,14.0728571 L4.5,12.0728571 Z M4.5,16.0728571 L5.5,16.0728571 L5.5,18.0728571 L4.5,18.0728571 L4.5,16.0728571 Z M10.5,8.07285714 L11.5,8.07285714 L11.5,10.0728571 L10.5,10.0728571 L10.5,8.07285714 Z M10.5,12.0728571 L11.5,12.0728571 L11.5,14.0728571 L10.5,14.0728571 L10.5,12.0728571 Z M10.5,16.0728571 L11.5,16.0728571 L11.5,18.0728571 L10.5,18.0728571 L10.5,16.0728571 Z M7.5,8.07285714 L8.5,8.07285714 L8.5,10.0728571 L7.5,10.0728571 L7.5,8.07285714 Z M7.5,12.0728571 L8.5,12.0728571 L8.5,14.0728571 L7.5,14.0728571 L7.5,12.0728571 Z M7.5,16.0728571 L8.5,16.0728571 L8.5,18.0728571 L7.5,18.0728571 L7.5,16.0728571 Z M13.5,8.07285714 L14.5,8.07285714 L14.5,10.0728571 L13.5,10.0728571 L13.5,8.07285714 Z M13.5,12.0728571 L14.5,12.0728571 L14.5,14.0728571 L13.5,14.0728571 L13.5,12.0728571 Z M13.5,16.0728571 L14.5,16.0728571 L14.5,18.0728571 L13.5,18.0728571 L13.5,16.0728571 Z M16.5,8.07285714 L17.5,8.07285714 L17.5,10.0728571 L16.5,10.0728571 L16.5,8.07285714 Z M16.5,12.0728571 L17.5,12.0728571 L17.5,14.0728571 L16.5,14.0728571 L16.5,12.0728571 Z"
      fillRule="nonzero"
    />
  </svg>
);
