// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2vtcjF_jw9LqtRk6wXYU1K {\n  font-family: Helvetica, sans-serif;\n}\n._2vtcjF_jw9LqtRk6wXYU1K ._3FKiIbMNj5Yl-lSAhagZ-r {\n  font-size: 16px;\n  line-height: 28px;\n  letter-spacing: normal;\n  margin: 0;\n  user-select: none;\n}\n._1JyCTaZMZCnjx-3aO7wWp6 {\n  border-top: 1px solid #e6e6e6;\n  padding-top: 30px;\n  margin-top: 30px;\n}\n._1JyCTaZMZCnjx-3aO7wWp6:first-child {\n  padding-top: 0;\n  border-top: 0;\n}\n._1JyCTaZMZCnjx-3aO7wWp6:last-child {\n  margin-bottom: 30px;\n}\n", "",{"version":3,"sources":["webpack://src/components/Base/components/Collapse/styles.styl"],"names":[],"mappings":"AAGA;EACE,kCAAa;AAFf;AAGE;EACE,eAAW;EACX,iBAAa;EACb,sBAAgB;EAChB,SAAQ;EACR,iBAAa;AADjB;AAKA;EACE,6BAAY;EACZ,iBAAa;EACb,gBAAY;AAHd;AAMA;EACE,cAAa;EACb,aAAY;AAJd;AAOA;EACE,mBAAe;AALjB","sourcesContent":["Basic300 = #E6E6E6;\n$fontFamily = Helvetica, sans-serif;\n\n.collapse {\n  font-family: $fontFamily;\n  .collapseHeader {\n    font-size: 16px;\n    line-height: 28px;\n    letter-spacing: normal;\n    margin: 0;\n    user-select: none;\n  }\n}\n\n.collapseBordered {\n  border-top: 1px solid Basic300;\n  padding-top: 30px;\n  margin-top: 30px;\n}\n\n.collapseBordered:first-child {\n  padding-top: 0;\n  border-top: 0;\n}\n\n.collapseBordered:last-child {\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapse": "_2vtcjF_jw9LqtRk6wXYU1K",
	"collapseHeader": "_3FKiIbMNj5Yl-lSAhagZ-r",
	"collapseBordered": "_1JyCTaZMZCnjx-3aO7wWp6"
};
export default ___CSS_LOADER_EXPORT___;
